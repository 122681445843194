import '@/bootstrap/monitor'
import '@/bootstrap/monitor/datadog-poc/datadog'
import './styles/dp-ui-kit/index.scss'
import './styles/index.scss'

import AvoTrack from '@lib/avo'
import Vue from 'vue'

import ModuleLoader from '@/bootstrap/ModuleLoader'
import monitor from '@/bootstrap/monitor/monitor'
import warnHandler from '@/bootstrap/vue.config.warnhandler'
import { ensureToggleIsEnabled } from '@/core/ui/plugins/featureToggle/featureToggle.util'
import { initialiseUnleashAnonymously } from '@/core/ui/plugins/featureToggle/providers/unleash'
import InitializationError from '@/core/ui/views/InitializationError.vue'
import { SSO_RENEW_STATE } from '@/features/auth/domain/constants/auth.constants'
import MFLoader from '@/features/moduleFederation/domain/moduleFederationLoader.service'
import SSORenew from '@/ssoRenew'
import { SET_AUTH_PROVIDER, SET_COUNTRY_SETTINGS, SET_CULTURE } from '@/store/mutation-types'
import { fetchInitializationConfig } from '@/utils/fetchInitializationConfig'
import { injectJQueryAndBootstrap, injectVWOInlineScript } from '@/utils/injectScripts'
import { getQueryParamsFromLocationSearch } from '@/utils/url'

import App from './App.vue'

Vue.config.productionTip = false
Vue.config.warnHandler = warnHandler
Vue.config.ignoredElements = ['patientus-video']

async function bootstrap() {
    const { state, code } = getQueryParamsFromLocationSearch()

    if (state === SSO_RENEW_STATE) {
        return SSORenew(code as string)
    }

    try {
        await initialiseUnleashAnonymously({
            toggles: {
                UseSSOTokenOnSaaS: {
                    value: false,
                    isRemote: true
                },
                UseVWOTool: {
                    value: false,
                    isRemote: true
                },
                UseJQueryAndBootstrap: {
                    value: false,
                    isRemote: true
                }
            }
        })
    } catch (e) {
        monitor.sendException(e)
    }

    if (ensureToggleIsEnabled('UseVWOTool', true)) {
        try {
            await injectVWOInlineScript()
        } catch (e) {
            monitor.sendException(e)
        }
    }

    if (ensureToggleIsEnabled('UseJQueryAndBootstrap', true)) {
        try {
            await injectJQueryAndBootstrap()
        } catch (e) {
            monitor.sendException(e)
        }
    }

    let initializationValues = {
        culture: undefined,
        provider: undefined,
        countrySettings: undefined
    }

    try {
        initializationValues = await fetchInitializationConfig()
    } catch (error: any) {
        monitor.sendException(error)

        Vue.prototype.$t = function (message: string) {
            return message
        }

        return new Vue({
            render: h => h(InitializationError, { props: { error } })
        }).$mount(`#${process.env.VUE_APP_ELEMENT_ID}`)
    }

    const moduleLoader = new ModuleLoader()

    await moduleLoader.loadModules()

    const injectableModules = moduleLoader.getInjectableModules()

    injectableModules.store.commit(SET_CULTURE, initializationValues.culture)
    injectableModules.store.commit(SET_AUTH_PROVIDER, initializationValues.provider)
    injectableModules.store.commit(SET_COUNTRY_SETTINGS, initializationValues.countrySettings)

    AvoTrack.init()

    const VueApp = new Vue({
        ...injectableModules,
        data() {
            return {
                loadingProgress: 100
            }
        },
        render: h => h(App)
    })

    VueApp.$mount(`#${process.env.VUE_APP_ELEMENT_ID}`)

    window.MFLoader = new MFLoader()

    return VueApp
}

export default bootstrap()
