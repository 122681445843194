export const CALENDAR_EVENT_DEFAULT_RESOURCE_ID = 'none'
export const CALENDAR_EVENT_MINIMAL_HEIGHT = 10
export const CALENDAR_MINIMAL_SNAP_DURATION = '00:05:00'
export const CALENDAR_DEFAULT_START_TIME = '08:00'
export const CALENDAR_DEFAULT_END_TIME = '20:00'
export const CALENDAR_DEFAULT_24H_START_TIME = '00:00'
export const CALENDAR_DEFAULT_24H_END_TIME = '24:00'
export const CALENDAR_DEFAULT_TIME_BOUNDARY_OFFSET = 2
export const CALENDAR_ROUNDING_INTERVAL = 30
export const CALENDAR_PREVIEW_ID = -1
export const CALENDAR_EVENT_PREVIEW_PREFIX = 'preview'
export const CALENDAR_POPOVER_WRAPPER_REF = 'appointment-popover'
export const CALENDAR_ATTENDANCE_POPOVER_WRAPPER_REF = 'attendance-popover'
export const CALENDAR_CONTEXTUAL_MENU_POPOVER_WRAPPER_REF = 'contextual-menu-popover'
export const CALENDAR_FETCH_EVENT_WEEKS_OFFSET = 0
export const RIGHT_MOUSE_BUTTON = 2
export const CALENDAR_RESOURCE_WEEK_DAY_MIN_WIDTH = 28
export const CALENDAR_SOURCE_NAME_BLOCK = 'blocks'
export const CALENDAR_SOURCE_NAME_REMINDER = 'reminders'
export const CALENDAR_DEFAULT_TIME_FORMAT = {
    hour: '2-digit',
    minute: '2-digit',
    meridiem: false,
    hour12: false
}
export const CALENDAR_APPOINTMENT_POPOVER_SCHEDULED_BY_DEFAULT = 'appointment-details-visit-from-marketplace'
export const CALENDAR_APPOINTMENT_POPOVER_SCHEDULED_BY_WAITING_LIST =
    'waiting-list-drawer-main-section-badge-booked-waiting-list'
